import { clsx } from 'clsx';
import { ArrowRightIcon } from 'nextra/icons';
import { Fragment } from 'react';
import { Anchor } from '@/components';
import { useNormalizedPages } from '@/nextra-theme/NormalizedPagesContext';

/**
 * Displays breadcrumbs at the top of docs pages.
 */
export function Breadcrumb() {
  const { activePath, activeType } = useNormalizedPages();

  // Pages don't have breadcrumbs, so don't render anything (by default,
  // activeType should be `doc` and so should render breadcrumbs)
  if (activeType === 'page') {
    return null;
  }

  return (
    <div className="mt-1.5 flex items-center gap-1 overflow-hidden text-sm uppercase text-gray-500 contrast-more:text-current dark:text-gray-400">
      {activePath.map((item, index) => {
        const isLink = item.children === undefined || item.withIndexPage;
        const isActive = index === activePath.length - 1;

        return (
          <Fragment key={item.route + item.name}>
            {index > 0 && <ArrowRightIcon className="w-3.5 shrink-0" />}
            <div
              className={clsx(
                'whitespace-nowrap transition-colors',
                isActive
                  ? 'font-medium text-gray-700 contrast-more:font-bold contrast-more:text-current dark:text-gray-100 contrast-more:dark:text-current'
                  : [
                      'min-w-[24px] overflow-hidden text-ellipsis',
                      isLink && 'hover:text-gray-900 dark:hover:text-gray-100',
                    ]
              )}
              title={item.title}
            >
              {isLink && !isActive ? (
                <Anchor href={item.route}>{item.title}</Anchor>
              ) : (
                item.title
              )}
            </div>
          </Fragment>
        );
      })}
    </div>
  );
}
